import React, { Component } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class App extends Component {
    state = {
        data: {
            email: '',
            name: '',
            phone: '',
            subject: '',
            message: ''
        },
        submitted: false
    };
    onChange = (e) => {
        return this.setState({
            data: { ...this.state.data, [e.target.name]: e.target.value }
        });
    };
    onSubmit = () => {
        console.log(JSON.stringify(this.state.data));
        const correctData = {};
        correctData.GuestName = this.state.data.name;
        correctData.Email = this.state.data.email;
        correctData.Phone = this.state.data.phone;
        correctData.MessageTitle = this.state.data.subject;
        correctData.Message = this.state.data.message; axios.post(
            'https://r58mdvrkl1.execute-api.us-east-1.amazonaws.com/v1/contact',
            correctData
        ).then(
            (response) => {
                toast.success(<p>Success</p>);
                console.log("Response: " + JSON.stringify(response));
                document.getElementById("contactForm").reset();
            },
            (error) => {
                toast.error(<p>Error</p>);
                console.log("Error " + error);
            }
        );
    };
    render() {
        return (
            <div>
            <form id="contactForm">
                <h4>Contact Form</h4>
                <div class="row">
                    <div class="col-6 mb-3">
                        <label for="name" class="form-label">*Your Name:</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                            placeholder="Your Name*"
                            onChange={this.onChange}
                        />
                    </div>
                    <div class="col-6 mb-3">
                        <label for="email" class="form-label">*Your Email:</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Your Email*"
                            onChange={this.onChange}
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 mb-3">
                        <label for="subject" class="form-label">*Subject:</label>
                        <input
                            type="text"
                            id="subject"
                            name="subject"
                            className="form-control"
                            placeholder="Subject*"
                            onChange={this.onChange}
                        />
                    </div>
                    <div class="col-6 mb-3">
                        <label for="phone" class="form-label">Phone:</label>
                        <input
                            type="text"
                            className="form-control"
                            id="phone"
                            name="phone"
                            placeholder="Phone"
                            onChange={this.onChange}
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb-3">
                        <label for="message" class="form-label">* Your Message:</label>
                        <textarea
                            name="message"
                            id="message"
                            className="form-control" rows="6"
                            placeholder="Your Message* ..."
                            onChange={this.onChange}
                        ></textarea>
                    </div>
                </div>
                <div class="buttons">
                    {!this.state.submitted && (
                        <button
                            type="button"
                            onClick={this.onSubmit}
                            disabled={this.state.loading}
                            className="btn btn-submit"
                        >
                            Send A Secure Message
                        </button>
                    )}
                </div>
            </form>
            <ToastContainer 
                position="bottom-right" 
                autoClose={5000} 
                hideProgressBar={false} 
                newestOnTop={false} closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            </div>
        )
    }
}
export default App;