import Contacts from './components/ContactForm';

function App() {
  return (
    <div className='App'>
      <Contacts />
    </div>
  );
}

export default App;
